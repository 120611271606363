<template>
	<div class="wrapper">
		<section class="agreement-section">
			<div class="container">
				<div class="agreement">
					<tk-button @click="goBack" kind="back-button">
						<template #icon-before>
							<tk-svg type="arrow-top" />
						</template>
						Назад
					</tk-button>
					<h1 class="section-title">Пользовательское соглашение</h1>
					<p class="text">
						Правила пользования приложением Teremok. <br />
						Добро пожаловать в приложение Teremok, интернет-ресурс, который
						помогает Вам быстро и удобно снимать и сдавать жильё по всему миру.
						Приложение Teremok – это сетевой проект, который решает проблему
						поиска жилья по всему миру. Администрация приложения предоставляет
						Вам доступ к использованию приложения Teremok и его функционала на
						условиях, являющихся предметом настоящих Правил пользования
						приложением Teremok. В этой связи Вам необходимо внимательно
						ознакомиться с условиями настоящих Правил, которые рассматриваются
						Администрацией приложения, как публичная оферта в соответствии со
						ст. 437 Гражданского кодекса Российской Федерации.
					</p>
					<ol>
						<li>
							<span class="text_bold"
								>Термины, используемые в настоящих Правилах</span
							>
							<ol>
								<li>
									Приложение Teremok (или Сайт, приложение) – приложение,
									известное под именем «Теремок», размещенная на сайте в сети
									Интернет по адресу: teremok.io (включая все уровни указанного
									домена, как функционирующие на дату принятия Пользователем
									настоящих Правил, так и запускаемые и вводимые в эксплуатацию
									в течение всего срока его действия) и доступная Пользователю
									через сайт, мобильную версию сайта, приложения и иные ресурсы,
									представляющая собой результат интеллектуальной деятельности в
									форме программы для ЭВМ. Приложение представлено в объективной
									форме совокупностью данных и команд, и порождаемых
									аудиовизуальных отображений (включая входящие в ее состав
									графические изображения и пользовательский интерфейс), (далее
									– данные и команды), предназначенных для функционирования ЭВМ
									и мобильных устройств в целях получения определенного
									результата в виде организации функционала приложения.
									Совокупность данных и команд состоит из активированных и
									неактивированных данных и команд.
								</li>
								<!--1.1-->
								<li>
									Неактивированные данные и команды – данные, команды и
									порождаемые аудиовизуальные отображения, позволяющие увеличить
									количество виртуальных ценностей, используемых в рамках
									функциональных возможностей приложения. Условия предоставления
									Администрацией Пользователю права на использование
									неактивированных данных и команд определены в лицензионном
									соглашении, действующая редакция которого располагается в
									свободном доступе в сети Интернет по адресу:
									https://teremok.io/licence, заключаемом Администрацией
									приложения с Пользователем.
								</li>
								<!--1.2-->
							</ol>
						</li>
						<!--1-->
						<li>
							<span class="text_bold"
								>Статус Правил пользования приложением Teremok</span
							>
							<ol>
								<li>
									Настоящие Правила пользования приложениемTeremok (ранее и
									далее – Правила) разработаны Администрацией приложения и
									определяют условия использования и развития приложения, а
									также права и обязанности его Пользователей и Администрации.
									Правила распространяются также на отношения, связанные с
									правами и интересами третьих лиц, не являющимися
									Пользователями приложения, но чьи права и интересы могут быть
									затронуты в результате действий Пользователей приложения.
								</li>
								<!--2.1-->
								<li>
									Настоящие Правила являются юридически обязательным соглашением
									между Пользователем и Администрацией приложения, предметом
									которого является предоставление Администрацией приложения
									Пользователю доступа к использованию приложения и его
									функционала. Помимо настоящих Правил, к соглашению между
									Пользователем и Администрацией приложения относятся все
									специальные документы, регулирующие предоставление доступа к
									использованию отдельного функционала приложения (в том числе
									его неактивированных данных и команд), размещенного в
									соответствующих разделах приложения в сети Интернет.
								</li>
								<!--2.2-->
								<li>
									Пользователь обязан полностью ознакомиться с настоящими
									Правилами до момента регистрации в приложении. Регистрация
									Пользователя в приложении означает полное и безоговорочное
									принятие Пользователем настоящих Правил в соответствии со ст.
									438 Гражданского кодекса Российской Федерации.
								</li>
								<!--2.3-->
								<li>
									Настоящие Правила могут быть изменены и/или дополнены
									Администрацией приложения в одностороннем порядке без
									какого-либо специального уведомления. Настоящие Правила
									являются открытым и общедоступным документом. Действующая
									редакция Правил располагается в сети Интернет по адресу:
									https://teremok.io/terms. Администрация приложения рекомендует
									Пользователям регулярно проверять условия настоящих Правил на
									предмет их изменения и/или дополнения. Продолжение
									использования приложения Пользователем после внесения
									изменений и/или дополнений в настоящие Правила означает
									принятие и согласие Пользователя с такими изменениями и/или
									дополнениями.
								</li>
								<!--2.4-->
							</ol>
						</li>
						<!--2-->
						<li>
							<span class="text_bold">Статус приложения Teremok</span>
							<ol>
								<li>
									Права на приложение в целом и на использование сетевого адреса
									(доменного имени) https://teremok.io принадлежат Администрации
									приложения. Последняя предоставляет доступ к приложению всем
									заинтересованным лицам в соответствии с настоящими Правилами и
									действующим законодательством Российской Федерации.
								</li>
								<!--3.1-->
								<li>
									Настоящими Правилами установлены условия, в соответствии с
									которыми права на использование информации и результатов
									интеллектуальной деятельности (включая, но не ограничиваясь
									литературными, музыкальными, аудиовизуальными произведениями и
									фонограммами, произведениями графики и дизайна,
									фотографическими произведениями, программами для ЭВМ) в
									составе отдельных разделов приложения, могут принадлежать
									Пользователям приложения и иным лицам, самостоятельно
									создавшим и/или разместившим указанные объекты в приложении
									без непосредственного участия Администрации приложения.
								</li>
								<!--3.2-->
							</ol>
						</li>
						<!--3-->
						<li>
							<span class="text_bold">Администрация приложения Teremok</span>
							<ol>
								<li>
									Под Администрацией приложения Teremok (ранее и далее –
									Администрация приложения или Администрация) в настоящих
									Правилах и иных специальных документах, размещенных в
									приложении, понимается Общество с Ограниченной
									Ответственностью "АРДЕРА ТЕЛЕКОМ", юридическое лицо, созданное
									по законодательству Российской Федерации и зарегистрированное
									по адресу: 119049, город Москва, ул. Шаболовка, д. 23, помещ.
									XLIVа эт 1.
								</li>
								<!--4.1-->
								<li>
									Обращения, предложения и претензии физических и юридических
									лиц к Администрации приложения в связи с настоящими Правилами
									и всеми вопросами по функционированию приложения, нарушениями
									прав и интересов третьих лиц при его использовании, а также
									для запросов уполномоченных законодательством Российской
									Федерации лиц могут быть направлены на почтовый адрес ООО
									"АРДЕРА ТЕЛЕКОМ", указанный в п. 4.1. настоящих Правил.
								</li>
								<!--4.2-->
								<li>
									В отношении функционирования и развития приложения,
									Администрация руководствуется законодательством Российской
									Федерации, настоящими Правилами и иными специальными
									документами, которые разработаны или могут быть разработаны и
									приняты Администрацией приложения в целях регламентации
									предоставления Пользователям доступа к отдельному функционалу
									приложения.
								</li>
								<!--4.3-->
								<li>
									Никакие положения настоящих Правил не предоставляют
									Пользователю право на использование фирменного наименования,
									товарных знаков, доменных имен и иных отличительных знаков
									Администрации приложения. Право на использование фирменного
									наименования, товарных знаков, доменных имен и иных
									отличительных знаков Администрации приложения может быть
									предоставлено исключительно по письменному соглашению с
									Администрацией приложения.
								</li>
								<!--4.4-->
							</ol>
						</li>
						<!--4-->
						<li>
							<span class="text_bold"
								>Регистрация в приложении Teremok и статус Пользователя</span
							>
							<ol>
								<li>
									Приложение Teremok является Сервисом. Регистрация Пользователя
									в приложении является бесплатной, добровольной и производится
									посредством заполнения полей Логин и Пароль, принятия
									пользовательского соглашения в приложении. Регистрация и/или
									авторизация в приложении, означает согласие с Правилами
									лицензионного соглашения, пользовательского соглашения,
									политики конфиденциальности приложения, которые размещены в
									открытом доступе в сети Интернет по адресу:
									https://teremok.io/terms.
								</li>
								<!--5.1-->
								<li>
									Пользователем приложения является физическое лицо,
									зарегистрированное в приложении в соответствии с установленным
									настоящими Правилами порядком, достигшее возраста, допустимого
									в соответствии с законодательством Российской Федерации для
									акцепта настоящих Правил, и обладающее соответствующими
									полномочиями (ранее и далее – Пользователь).
								</li>
								<!--5.2-->
								<li>
									При регистрации в приложении Пользователь обязан предоставить
									Администрации приложения необходимую достоверную и актуальную
									информацию для формирования персональной страницы (аккаунта)
									Пользователя, включая уникальные для каждого Пользователя
									логин и пароль доступа к приложению. Регистрационная форма
									приложения может запрашивать у Пользователя дополнительную
									информацию.
								</li>
								<!--5.3-->
								<li>
									Пользователь несет ответственность за достоверность,
									актуальность, полноту и соответствие законодательству
									Российской Федерации предоставленной при регистрации
									информации и ее чистоту от претензий третьих лиц.
								</li>
								<!--5.4-->
								<li>
									После предоставления информации, указанной в п. 5.3. настоящих
									Правил, Пользователю необходимо пройти ряд удостоверительных
									процедур, а именно, (б) подтвердить регистрацию путем введения
									в соответствующую форму в приложении кода, полученного
									Пользователем в виде sms-сообщения от Администрации приложения
									на мобильный телефон Пользователя, номер которого
									предоставляется самим Пользователем. В случае верного
									последовательного выполнения всех регистрационных действий в
									приложении создается персональная страница (аккаунт)
									Пользователя, имеющая сетевой адрес вида
									https://teremok.io/profile/[uuid] или вида
									https://teremok.io/profile. Пользователь вправе
									зарегистрировать не более одной персональной страницы
									(аккаунта) в приложении.
								</li>
								<!--5.5-->
								<li>
									При регистрации Пользователь соглашается с настоящими
									Правилами и принимает на себя указанные в них права и
									обязанности, связанные с использованием и функционированием
									приложения. Пользователь соглашается на получение посредством
									сервисов Администрации приложения и/или сервисов третьих лиц
									электронных сообщений, sms и иных видов рассылок
									информационного, в т.ч. рекламно-информационного, содержания,
									в том числе от партнеров Администрации приложения. Право
									использования неактивированных данных и команд предоставляется
									Пользователю при выполнении дополнительно к указанному выше
									условий лицензионного соглашения (см. п. 1.2. настоящих
									Правил).
								</li>
								<!--5.6-->
								<li>
									После успешной регистрации Пользователя в приложении
									Администрация принимает на себя права и обязанности перед
									Пользователем, указанные в настоящих Правилах.
								</li>
								<!--5.7-->
								<li>
									Обработка персональных данных Пользователя осуществляется в
									соответствии с законодательством Российской Федерации.
									Администрация приложения обрабатывает персональные данные
									Пользователя в целях предоставления Пользователю доступа к
									использованию функционала приложения, в том числе в целях
									получения Пользователем таргетированной рекламы; проверки,
									исследования и анализа таких данных, позволяющих поддерживать
									и улучшать функционал и разделы приложения, а также
									разрабатывать новый функционал и разделы приложения.
									Администрация приложения принимает все необходимые меры для
									защиты персональных данных Пользователя от неправомерного
									доступа, изменения, раскрытия или уничтожения. Администрация
									предоставляет доступ к персональным данным Пользователя только
									тем работникам, подрядчикам и агентам Администрации, которым
									эта информация необходима для обеспечения функционирования
									приложения и предоставления Пользователю доступа к его
									использованию. Администрация приложения вправе использовать
									предоставленную Пользователем информацию, в том числе
									персональные данные, а также передавать ее третьим лицам, в
									целях обеспечения соблюдения требований действующего
									законодательства Российской Федерации, защиты прав и интересов
									Пользователей, Администрации приложения, третьих лиц (в том
									числе в целях выявления, проверки/расследования и/или
									пресечения противоправных действий). Раскрытие предоставленной
									Пользователем информации может быть произведено лишь в
									соответствии с действующим законодательством Российской
									Федерации по требованию суда, правоохранительных органов, а
									равно в иных предусмотренных законодательством Российской
									Федерации случаях. Поскольку Администрация приложения
									осуществляет обработку персональных данных Пользователя в
									целях исполнения настоящих Правил, в силу положений
									законодательства о персональных данных согласие Пользователя
									на обработку его персональных данных не требуется.
								</li>
								<!--5.8-->
								<li>
									Выбранные Пользователем логин и пароль являются необходимой и
									достаточной информацией для доступа Пользователя в приложение.
									Пользователь не имеет права передавать свои логин и пароль
									третьим лицам, несет полную ответственность за их сохранность,
									самостоятельно выбирая способ их хранения. Пользователь на
									используемом им аппаратно-программном обеспечении может
									разрешить хранение логина и пароля (с использованием файлов
									cookies) для последующей автоматической авторизации в
									приложении.
								</li>
								<!--5.9-->
								<li>
									Если Пользователем не доказано обратное, любые действия,
									совершенные с использованием его логина и пароля, считаются
									совершенными соответствующим Пользователем. В случае
									несанкционированного доступа к логину и паролю и/или
									персональной странице (аккаунту) Пользователя, или
									распространения логина и пароля Пользователь обязан
									незамедлительно сообщить об этом Администрации приложения в
									установленном порядке.
								</li>
								<!--5.10-->
								<li>
									После регистрации Пользователь получает право самостоятельно в
									личных целях создавать, использовать и определять содержание
									собственной персональной страницы (аккаунта) и условия доступа
									других Пользователей к ее (его) содержанию.
								</li>
								<!--5.11-->
								<li>
									Пользователь как обладатель информации, размещенной на
									собственной персональной странице (аккаунте), осознает, что за
									исключением случаев, установленных настоящими Правилами и
									действующим законодательством Российской Федерации,
									Администрация приложения не принимает участие в формировании и
									использовании содержания и контроле доступа других
									пользователей к персональной странице (аккаунту) Пользователя.
								</li>
								<!--5.12-->
								<li>
									Размещая информацию на персональной странице (аккаунте), в том
									числе свои персональные данные, Пользователь осознает и
									соглашается с тем, что указанная информация может быть
									доступна другим пользователям сети Интернет с учетом
									особенностей архитектуры и функционала приложения.
								</li>
								<!--5.13-->
								<li>
									В отношении информации о себе Пользователь имеет право: (а)
									удалять ранее размещенную Пользователем информацию в
									приложении посредством обращения в техническую поддержку
									приложения, через раздел "обратная связь".
								</li>
								<!--5.14-->
							</ol>
						</li>
						<!--5-->
						<li>
							<span class="text_bold"
								>Обязанности Пользователя приложения Teremok</span
							>
							<ol>
								<li>
									При использовании приложения Пользователь обязан: соблюдать
									положения действующего законодательства Российской Федерации,
									настоящих Правил и иных специальных документов Администрации
									приложения; предоставлять при регистрации достоверные, полные
									и актуальные данные, следить за их актуализацией;
									информировать Администрацию приложения о несанкционированном
									доступе к персональной странице и/или о несанкционированном
									доступе и/или использовании пароля и логина Пользователя; не
									предоставлять доступ другим Пользователям к собственной
									персональной странице (аккаунту) или к отдельной содержащейся
									на ней информации в случае, если это может привести к
									нарушению законодательства Российской Федерации и/или
									настоящих Правил, специальных документов Администрации
									приложения; не размещать на персональной странице (аккаунте)
									информацию и объекты (включая ссылки на них), которые могут
									нарушать права и интересы других лиц; перед размещением
									информации и объектов (включая, но не ограничиваясь,
									изображениями других лиц, чужими текстами различного
									содержания, аудиозаписями и видеофильмами) предварительно
									оценивать законность их размещения; хранить в тайне и не
									предоставлять другим Пользователям и третьим лицам ставшие ему
									известными в результате общения с другими Пользователями и
									иного использования приложения персональные данные (включая,
									но не ограничиваясь, домашними адресами, номерами телефонов,
									адресами электронной почты, ICQ, паспортными данными,
									банковской информацией) и информацию о частной жизни других
									Пользователей и третьих лиц без получения соответствующего
									предварительного разрешения последних; осуществлять резервное
									копирование важной для Пользователя хранящейся на его
									персональной странице (аккаунте) информации.
								</li>
								<!--6.1-->
								<li>
									В случае наличия сомнений в отношении законности осуществления
									тех или иных действий, в том числе по размещению информации
									или предоставлению доступа, Администрация приложения
									рекомендует воздержаться от осуществления последних.
								</li>
								<!--6.2-->
								<li>
									Пользователю при использовании приложения запрещается:
									<ol>
										<li>
											регистрироваться в качестве Пользователя от имени или
											вместо другого лица («фальшивый аккаунт») или
											регистрировать группу (объединение) лиц или юридическое
											лицо в качестве Пользователя. При этом возможна
											регистрация от имени и по поручению другого физического
											лица или юридического лица при условии получения
											необходимых полномочий в порядке и форме, предусмотренных
											законодательством Российской Федерации;
										</li>
										<!--6.3.1-->
										<li>
											вводить Пользователей в заблуждение относительно своей
											личности, используя логин и пароль другого
											зарегистрированного Пользователя;
										</li>
										<!--6.3.2-->
										<li>
											искажать сведения о себе, своем возрасте или своих
											отношениях с другими лицами или организациями;
										</li>
										<!--6.3.3-->
										<li>
											загружать, хранить, публиковать, распространять и
											предоставлять доступ или иным образом использовать любую
											информацию, которая: (а) содержит угрозы, призывы к
											насилию, в том числе скрытые, одобрение и поощрение
											насильственных действий, дискредитирует, оскорбляет,
											порочит честь и достоинство или деловую репутацию или
											нарушает неприкосновенность частной жизни других
											Пользователей или третьих лиц; (б) нарушает права
											несовершеннолетних лиц; (в) является вульгарной или
											непристойной, содержит порнографические изображения и
											тексты или сцены сексуального характера с участием
											несовершеннолетних; (г) содержит сцены бесчеловечного
											обращения с животными; (д) содержит описание средств и
											способов суицида, любое подстрекательство к его
											совершению; (е) пропагандирует и/или способствует
											разжиганию расовой, религиозной, этнической ненависти или
											вражды, по признакам отнесения к определенным полу,
											ориентации, а также по иным индивидуальным признакам и
											особенностям человека (включая вопросы его здоровья); (ж)
											содержит экстремистские материалы; (з) пропагандирует
											преступную деятельность или содержит советы, инструкции
											или руководства по совершению преступных действий; (и)
											содержит информацию ограниченного доступа, включая, но не
											ограничиваясь, государственной и коммерческой тайной,
											информацией о частной жизни третьих лиц; (к) содержит
											рекламу или описывает привлекательность употребления
											наркотических веществ, в том числе «цифровых наркотиков»
											(звуковых файлов, оказывающих воздействие на мозг человека
											за счет бинауральных ритмов), информацию о распространении
											наркотиков, рецепты их изготовления и советы по
											употреблению; (л) потенциально может привести к совершению
											противоправных действий путем введения Пользователей в
											заблуждение или злоупотребления их доверием; (м) а также
											нарушает иные права и интересы граждан и юридических лиц
											или требования законодательства Российской Федерации.
										</li>
										<!--6.3.4-->
										<li>
											незаконно загружать, хранить, публиковать, распространять
											и предоставлять доступ или иным образом использовать
											интеллектуальную собственность Пользователей и третьих
											лиц;
										</li>
										<!--6.3.5-->
										<li>
											осуществлять массовые рассылки сообщений в адрес других
											Пользователей приложения без их предварительного согласия
											и/или любые другие аналогичные нежелательные рассылки
											(спам), в том числе с использованием отдельных
											функциональных возможностей приложения (например,
											счётчиков приложения, предусмотренных п. 6.3.16 настоящих
											Правил);
										</li>
										<!--6.3.6-->
										<li>
											использовать программное обеспечение и осуществлять
											действия, направленные на нарушение нормального
											функционирования приложения или персональных страниц
											Пользователей;
										</li>
										<!--6.3.7-->
										<li>
											загружать, хранить, публиковать, распространять и
											предоставлять доступ или иным образом использовать вирусы,
											трояны и другие вредоносные программы;
										</li>
										<!--6.3.8-->
										<li>
											использовать без специального на то разрешения
											Администрации приложения автоматизированные скрипты
											(программы, боты, краулеры) для сбора информации в
											приложении и/или взаимодействия с приложением и его
											функционалом;
										</li>
										<!--6.3.9-->
										<li>
											любым способом, в том числе, но не ограничиваясь, путем
											обмана, злоупотребления доверием, взлома, пытаться
											получить доступ к логину и паролю другого Пользователя;
										</li>
										<!--6.3.10-->
										<li>
											осуществлять незаконные сбор и обработку персональных
											данных других лиц;
										</li>
										<!--6.3.11-->
										<li>
											осуществлять использование приложения иным способом, кроме
											как через интерфейс, предоставленный Администрацией
											приложения, за исключением случаев, когда такие действия
											были прямо разрешены Пользователю в соответствии с
											отдельным соглашением с Администрацией;
										</li>
										<!--6.3.12-->
										<li>
											воспроизводить, дублировать, копировать, продавать,
											осуществлять торговые операции и перепродавать доступ к
											использованию приложения, в том числе его неактивированных
											данных и команд, для каких-либо целей, за исключением
											случаев, когда такие действия были прямо разрешены
											Пользователю в соответствии с условиями настоящих Правил
											или отдельного соглашения с Администрацией;
										</li>
										<!--6.3.13-->
										<li>
											размещать коммерческую и политическую рекламу вне
											специальных разделов приложения, установленных
											Администрацией приложения;
										</li>
										<!--6.3.14-->
										<li>
											размещать любую другую информацию, которая, по личному
											мнению Администрации, является нежелательной, не
											соответствует целям создания приложения, ущемляет интересы
											Пользователей или по другим причинам является
											нежелательной для размещения в приложении;
										</li>
										<!--6.3.15-->
										<li>
											осуществлять самостоятельно либо от имени других
											Пользователей с использованием функционала их аккаунта, в
											том числе путем введения в заблуждение или с обещанием
											поощрения, в том числе с использованием любых программ,
											автоматизированных скриптов, массовые однотипные действия,
											направленные на искусственное повышение показателей
											счётчиков приложения.
										</li>
										<!--6.3.16-->
									</ol>
								</li>
								<!--6.3-->
								<li>
									Пользователь несет личную ответственность за любую информацию,
									которую размещает в приложении, сообщает другим Пользователям,
									а также за любые взаимодействия с другими Пользователями,
									осуществляемые на свой риск.
								</li>
								<!--6.4-->
								<li>
									В случае несогласия Пользователя с настоящими Правилами или их
									обновлениями, Пользователь обязан отказаться от использования
									приложения, проинформировав об этом Администрацию приложения в
									установленном порядке.
								</li>
								<!--6.5-->
								<li>
									Приобретение Пользователем права использования
									неактивированных данных и команд приложения осуществляется на
									основании лицензионного соглашения, текст которого расположен
									в свободном доступе в сети Интернет по адресу:
									https://teremok.io/licence, заключаемого Администрацией
									приложения с Пользователем.
								</li>
								<!--6.6-->
							</ol>
						</li>
						<!--6-->
						<li>
							<span class="text_bold">Условия об интеллектуальных правах</span>
							<ol>
								<li>
									Исключительные права на Контент, размещенный в приложении.
									<ol>
										<li>
											Все объекты, размещенные в приложении, в том числе
											элементы дизайна, текст, графические изображения,
											иллюстрации, видео, скрипты, программы, музыка, звуки и
											другие объекты и их подборки (далее — Контент), являются
											объектами исключительных прав Администрации, Пользователей
											приложения и других правообладателей, все права на эти
											объекты защищены.
										</li>
										<!--7.1.1-->
										<li>
											Кроме случаев, установленных настоящими Правилами, а также
											действующим законодательством Российской Федерации,
											никакой Контент не может быть скопирован (воспроизведен),
											переработан, распространен, отображен во фрейме,
											опубликован, скачан, передан, продан или иным способом
											использован целиком или по частям без предварительного
											разрешения правообладателя, кроме случаев, когда
											правообладатель явным образом выразил свое согласие на
											свободное использование Контента любым лицом.
											Воспроизведение, копирование, сбор, систематизация,
											хранение, передача Контента с целью создания базы данных в
											коммерческих и/или некоммерческих целях и/или
											использование Контента полностью или в любой его части,
											независимо от способа использования, без согласия
											Администрации не допускается.
										</li>
										<!--7.1.2-->
										<li>
											Пользователь, размещая в приложении принадлежащий ему на
											законных основаниях Контент, предоставляет другим
											пользователям неисключительное право на его использование
											исключительно в рамках предоставляемого приложением
											функционала, путем просмотра, воспроизведения (в том числе
											копирования) и иные права исключительно с целью личного
											некоммерческого использования, кроме случаев, когда такое
											использование причиняет или может причинить вред
											охраняемым законом интересам правообладателя.
										</li>
										<!--7.1.3-->
										<li>
											Использование Пользователем Контента, доступ к которому
											получен исключительно для личного некоммерческого
											использования, допускается при условии сохранения всех
											знаков авторства (копирайтов) или других уведомлений об
											авторстве, сохранения имени автора в неизменном виде,
											сохранении произведения в неизменном виде.
										</li>
										<!--7.1.4-->
										<li>
											Пользователь предоставляет также Администрации приложения
											неисключительное право использовать на безвозмездной
											основе размещенный в приложении и принадлежащий ему на
											законных основаниях Контент в целях обеспечения
											Администрацией приложения функционирования приложения в
											объеме, определяемом функционалом и архитектурой
											приложения, и отображения Контента в промоматериалах
											Администрации приложения, в том числе в рамках изображений
											интерфейса приложения, в том числе путём доведения таких
											промоматериалов до всеобщего сведения. Указанное
											неисключительное право предоставляется на срок размещения
											Контента в приложении и распространяет свое действие на
											территории стран всего мира. Окончание срока размещения
											Контента в приложении и/или срока действия
											неисключительного права не влечет за собой необходимость
											изъятия из оборота промоматериалов Администрации
											приложения с отображением Контента (в том числе их
											удаление из сети Интернет). Администрация приложения
											вправе передавать права, указанные в настоящем пункте
											третьим лицам. Пользователь соглашается с тем, что
											Администрация вправе использовать функциональные и
											технические возможности приложения, обеспечивающие
											отображение размещаемого Пользователем Контента.
										</li>
										<!--7.1.5-->
										<li>
											Если Пользователь удаляет свой Контент с приложения,
											неисключительное право, упомянутое в п. 7.1.5. настоящих
											Правил, будет автоматически отозвано, однако Администрация
											оставляет за собой право в случае необходимости,
											обусловленной техническими особенностями работы
											приложения, сохранять архивные копии пользовательского
											Контента в течение необходимого срока.
										</li>
										<!--7.1.6-->
										<li>
											Кроме своего собственного Контента, Пользователь не вправе
											загружать или иным образом доводить до всеобщего сведения
											(публиковать в приложении) Контент других сайтов, баз
											данных и прочие результаты интеллектуальной деятельности
											при отсутствии явным образом выраженного согласия
											правообладателя на такие действия.
										</li>
										<!--7.1.7-->
										<li>
											Любое использование приложения или Контента, кроме
											разрешенного в настоящих Правилах или в случае явно
											выраженного согласия правообладателя на такое
											использование, без предварительного письменного разрешения
											правообладателя, категорически запрещено.
										</li>
										<!--7.1.8-->
										<li>
											Если иное явным образом не установлено в настоящих
											Правилах, ничто в настоящих Правилах не может быть
											рассмотрено как передача исключительных прав на Контент.
										</li>
										<!--7.1.9-->
									</ol>
								</li>
								<!--7.1-->
								<li>
									Ответственность за нарушение исключительных прав.
									<ol>
										<li>
											Пользователь несет личную ответственность за любой Контент
											или иную информацию, которые он загружает или иным образом
											доводит до всеобщего сведения (публикует) в приложении или
											с его помощью. Пользователь не имеет права загружать,
											передавать или публиковать Контент в приложении, если он
											не обладает соответствующими правами на совершение таких
											действий, приобретенными или переданными ему в
											соответствии с законодательством Российской Федерации. При
											обнаружении нарушения прав для подачи жалоб используются
											Правила рассмотрения жалоб на пользовательский контент.
										</li>
										<!--7.2.1-->
										<li>
											Администрация приложения может, но не обязана,
											просматривать Сайт на наличие запрещенного Контента и
											может удалять или перемещать (без предупреждения) любой
											Контент или пользователей по своему личному усмотрению, по
											любой причине или без причины, включая без всяких
											ограничений перемещение или удаление Контента, который, по
											личному мнению Администрации, нарушает настоящие Правила,
											законодательство Российской Федерации и/или может нарушать
											права, причинить вред или угрожать безопасности других
											Пользователей или третьих лиц.
										</li>
										<!--7.2.2-->
									</ol>
								</li>
								<!--7.2-->
								<li>
									Сайты и Контент третьих лиц.
									<ol>
										<li>
											Сайт содержит (или может содержать) ссылки на другие сайты
											в сети Интернет (сайты третьих лиц) так же, как и статьи,
											фотографии, иллюстрации, графические изображения, музыку,
											звуки, видео, информацию, приложения, программы и другой
											Контент, принадлежащий или исходящий от третьих лиц
											(Контент третьих лиц), являющийся результатом
											интеллектуальной деятельности и охраняемых в соответствии
											с законодательством Российской Федерации.
										</li>
										<!--7.3.1-->
										<li>
											Указанные третьи лица и их Контент не проверяются
											Администрацией на соответствие тем или иным требованиям
											(достоверности, полноты, добросовестности и т.п.).
											Администрация не несет ответственность за любую
											информацию, размещенную на сайтах третьих лиц, к которым
											Пользователь получает доступ через Сайт или через Контент
											третьих лиц, включая, в том числе, любые мнения или
											утверждения, выраженные на сайтах третьих лиц или в их
											Контенте.
										</li>
										<!--7.3.2-->
										<li>
											Размещенные в приложении ссылки или руководства по
											скачиванию файлов и/или установке программ третьих лиц не
											означают поддержки или одобрения этих действий со стороны
											Администрации.
										</li>
										<!--7.3.3-->
										<li>
											Ссылка на любой сайт, продукт, услугу, любую информацию
											коммерческого или некоммерческого характера, размещенная в
											приложении, не является одобрением или рекомендацией
											данных продуктов (услуг) со стороны Администрации.
										</li>
										<!--7.3.4-->
										<li>
											Если Пользователь решил покинуть приложение и перейти к
											сайтам третьих лиц или использовать, или установить
											программы третьих лиц, он делает это на свой риск и с
											этого момента настоящие Правила более не распространяются
											на Пользователя. При дальнейших действиях Пользователю
											стоит руководствоваться применимыми нормами и политикой, в
											том числе деловыми обычаями тех лиц, чей Контент он
											собирается использовать.
										</li>
										<!--7.3.5-->
									</ol>
								</li>
								<!--7.3-->
							</ol>
						</li>
						<!--7-->
						<li>
							<span class="text_bold"
								>Функционирование приложения Teremok и ответственность при его
								использовании</span
							>
							<ol>
								<li>
									Пользователи несут ответственность за собственные действия в
									связи с созданием и размещением информации на собственной
									персональной странице (аккаунте) в приложении, а также в связи
									с размещением информации на персональных страницах других
									Пользователей и в иных разделах приложения в соответствии с
									действующим законодательством Российской Федерации. Нарушение
									настоящих Правил и действующего законодательства Российской
									Федерации влечет за собой гражданско-правовую,
									административную и уголовную ответственность.
								</li>
								<!--8.1-->
								<li>
									Администрация приложения предоставляет техническую возможность
									его использования Пользователями, не участвует в формировании
									содержания персональных страниц Пользователей и не
									контролирует и не несет ответственности за действия или
									бездействие любых лиц в отношении использования приложения или
									формирования и использования содержания персональных страниц
									(аккаунтов) Пользователей в приложении.
								</li>
								<!--8.2-->
								<li>
									В информационной системе приложения и его программном
									обеспечении отсутствуют технические решения, осуществляющие
									автоматические цензуру и контроль действий и информационных
									отношений Пользователей по использованию приложения, за
									исключением специальных технических решений, которые могут
									реализовываться Администрацией в целях предотвращения и
									пресечения нарушений прав третьих лиц на результаты
									интеллектуальной деятельности.
								</li>
								<!--8.3-->
								<li>
									Администрация сохраняет за собой право в любое время изменять
									оформление приложения, его содержание, функционал, изменять
									или дополнять используемые скрипты, программное обеспечение и
									другие объекты, используемые или хранящиеся в приложении,
									любые серверные приложения в любое время с предварительным
									уведомлением или без такового.
								</li>
								<!--8.4-->
								<li>
									Администрация приложения не занимается предварительной
									модерацией или цензурой информации Пользователей и
									предпринимает действия по защите прав и интересов лиц и
									обеспечению соблюдения требований законодательства Российской
									Федерации только после обращения заинтересованного лица к
									Администрации приложения в установленном порядке.
								</li>
								<!--8.5-->
								<li>
									Администрация приложения не несет ответственности за нарушение
									Пользователем настоящих Правил и оставляет за собой право по
									своему собственному усмотрению, а также при получении
									информации от других пользователей либо третьих лиц о
									нарушении Пользователем настоящих Правил, изменять
									(модерировать), блокировать или удалять любую публикуемую
									Пользователем информацию, нарушающую запреты, установленные
									настоящими Правилами, приостанавливать, ограничивать или
									прекращать доступ Пользователя ко всем или к любому из
									разделов или функционалу приложения, исключать из системы
									поиска и/или заблокировать "Поиск объявлений" в любое время по
									любой причине или без объяснения причин, с предварительным
									уведомлением или без такового. Администрация приложения
									закрепляет за собой право удалить персональную страницу
									(аккаунт) Пользователя и/или приостановить, ограничить или
									прекратить доступ Пользователя к любой из функциональных
									возможностей приложения, если Администрация обнаружит, что по
									ее мнению, Пользователь представляет угрозу для приложения
									и/или его Пользователей. Наряду с указанным в настоящем пункте
									выше Администрация приложения вправе блокировать и/или удалять
									аккаунт Пользователя или контент, публикуемый Пользователем в
									случае если у Администрации приложения появятся основания
									полагать, что такой контент или аккаунт представляют или могут
									представлять угрозу для нормальной работы приложения и его
									Пользователей. Администрация приложения реализует описанные
									выше меры в соответствии с применимым законодательством и не
									несет ответственности за возможные негативные последствия
									таких мер для Пользователя или третьих лиц.
								</li>
								<!--8.6-->
								<li>
									После удаления персональной страницы (аккаунта) Пользователь
									теряет доступ к использованию приложения. Удаление
									персональной страницы (аккаунта) Пользователя означает
									автоматическое удаление всей информации, размещенной на ней, а
									также всей информации Пользователя, введенной при регистрации
									в приложении.
								</li>
								<!--8.7-->
								<li>
									Администрация приложения обеспечивает функционирование и
									работоспособность приложения и обязуется оперативно
									восстанавливать его работоспособность в случае технических
									сбоев и перерывов. Администрация приложения не несет
									ответственности за временные сбои и перерывы в работе
									приложения и вызванные ими потерю информации. Администрация не
									несет ответственности за любой ущерб компьютеру Пользователя
									или иного лица, мобильным устройствам, любому другому
									оборудованию или программному обеспечению, вызванный или
									связанный со скачиванием материалов с приложения или по
									ссылкам, размещенным в приложении.
								</li>
								<!--8.8-->
								<li>
									Администрация приложения имеет право распоряжаться
									статистической информацией, связанной с функционированием
									приложения, а также информацией Пользователей для обеспечения
									адресного показа рекламной информации различным аудиториям
									Пользователей приложения. Для целей организации
									функционирования и технической поддержки приложения и
									исполнения настоящих Правил Администрация приложения имеет
									техническую возможность доступа к персональным страницам
									(аккаунтам) Пользователей, которую реализует только в случаях,
									установленных настоящими Правилами.
								</li>
								<!--8.9-->
								<li>
									Администрация приложения имеет право направлять Пользователю
									информацию о развитии приложения и его функционала, а также
									рекламировать собственную деятельность.
								</li>
								<!--8.10-->
								<li>
									Ограничение ответственности Администрации приложения:
									<ol>
										<li>
											САЙТ И ЕГО ФУНКЦИОНАЛ, ВКЛЮЧАЯ ВСЕ СКРИПТЫ, ПРИЛОЖЕНИЯ,
											КОНТЕНТ И ОФОРМЛЕНИЕ САЙТА ПОСТАВЛЯЮТСЯ «КАК ЕСТЬ».
											АДМИНИСТРАЦИЯ ОТКАЗЫВАЕТСЯ ОТ ВСЯКИХ ГАРАНТИЙ ТОГО, ЧТО
											САЙТ ИЛИ ЕГО ФУНКЦИОНАЛ МОГУТ ПОДХОДИТЬ ИЛИ НЕ ПОДХОДИТЬ
											ДЛЯ КОНКРЕТНЫХ ЦЕЛЕЙ ИСПОЛЬЗОВАНИЯ. АДМИНИСТРАЦИЯ НЕ МОЖЕТ
											ГАРАНТИРОВАТЬ И НЕ ОБЕЩАЕТ НИКАКИХ СПЕЦИФИЧЕСКИХ
											РЕЗУЛЬТАТОВ ОТ ИСПОЛЬЗОВАНИЯ САЙТА И/ИЛИ ЕГО ФУНКЦИОНАЛА;
										</li>
										<!--8.11.1-->
										<li>
											ВО ИЗБЕЖАНИЕ НЕДОРАЗУМЕНИЙ ПОЛЬЗОВАТЕЛЮ СЛЕДУЕТ СОБЛЮДАТЬ
											МЕРЫ ПРЕДОСТОРОЖНОСТИ В СКАЧИВАНИИ С САЙТА ИЛИ ПО
											РАЗМЕЩЕННЫМ НА НЕМ ССЫЛКАМ, И ИСПОЛЬЗОВАНИИ ЛЮБЫХ ФАЙЛОВ,
											В ТОМ ЧИСЛЕ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ. АДМИНИСТРАЦИЯ САЙТА
											НАСТОЯТЕЛЬНО РЕКОМЕНДУЕТ ИСПОЛЬЗОВАТЬ ТОЛЬКО ЛИЦЕНЗИОННОЕ,
											В ТОМ ЧИСЛЕ АНТИВИРУСНОЕ, ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ;
										</li>
										<!--8.11.2-->
										<li>
											ИСПОЛЬЗУЯ САЙТ, ПОЛЬЗОВАТЕЛЬ СОГЛАШАЕТСЯ С ТЕМ, ЧТО
											СКАЧИВАЕТ С САЙТА ИЛИ С ЕГО ПОМОЩЬЮ ЛЮБЫЕ МАТЕРИАЛЫ НА
											СВОЙ СОБСТВЕННЫЙ РИСК И НЕСЕТ ЛИЧНУЮ ОТВЕТСТВЕННОСТЬ ЗА
											ВОЗМОЖНЫЕ ПОСЛЕДСТВИЯ ИСПОЛЬЗОВАНИЯ УКАЗАННЫХ МАТЕРИАЛОВ,
											В ТОМ ЧИСЛЕ ЗА УЩЕРБ, КОТОРЫЙ ЭТО МОЖЕТ ПРИЧИНИТЬ
											КОМПЬЮТЕРУ ПОЛЬЗОВАТЕЛЯ ИЛИ ТРЕТЬИМ ЛИЦАМ, ЗА ПОТЕРЮ
											ДАННЫХ ИЛИ ЛЮБОЙ ДРУГОЙ ВРЕД;
										</li>
										<!--8.11.3-->
										<li>
											НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ АДМИНИСТРАЦИЯ САЙТА ИЛИ ЕЕ
											ПРЕДСТАВИТЕЛИ НЕ НЕСУТ ОТВЕТСТВЕННОСТЬ ПЕРЕД ПОЛЬЗОВАТЕЛЕМ
											ИЛИ ПЕРЕД ЛЮБЫМИ ТРЕТЬИМИ ЛИЦАМИ ЗА ЛЮБОЙ КОСВЕННЫЙ,
											СЛУЧАЙНЫЙ, НЕУМЫШЛЕННЫЙ УЩЕРБ, ВКЛЮЧАЯ УПУЩЕННУЮ ВЫГОДУ
											ИЛИ ПОТЕРЯННЫЕ ДАННЫЕ, ВРЕД ЧЕСТИ, ДОСТОИНСТВУ ИЛИ ДЕЛОВОЙ
											РЕПУТАЦИИ, ВЫЗВАННЫЙ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ САЙТА,
											СОДЕРЖИМОГО САЙТА ИЛИ ИНЫХ МАТЕРИАЛОВ, К КОТОРЫМ ВЫ ИЛИ
											ИНЫЕ ЛИЦА ПОЛУЧИЛИ ДОСТУП С ПОМОЩЬЮ САЙТА, ДАЖЕ ЕСЛИ
											АДМИНИСТРАЦИЯ САЙТА ПРЕДУПРЕЖДАЛА ИЛИ УКАЗЫВАЛА НА
											ВОЗМОЖНОСТЬ ТАКОГО ВРЕДА.
										</li>
										<!--8.11.4-->
									</ol>
								</li>
								<!--8.11-->
							</ol>
						</li>
						<!--8-->
						<li>
							<span class="text_bold">Заключительные положения</span>
							<ol>
								<li>
									Настоящие Правила составляют соглашение между Пользователем и
									Администрацией приложения относительно порядка использования
									приложения и его функционала и заменяют собой все предыдущие
									соглашения между Пользователем и Администрацией.
								</li>
								<!--9.1-->
								<li>
									Настоящие Правила регулируются и толкуются в соответствии с
									законодательством Российской Федерации. Вопросы, не
									урегулированные Правилами, подлежат разрешению в соответствии
									с законодательством Российской Федерации.
								</li>
								<!--9.2-->
								<li>
									В случае возникновения любых споров или разногласий, связанных
									с исполнением настоящих Правил, Пользователь и Администрация
									приложения приложат все усилия для их разрешения путем
									проведения переговоров между ними. В случае, если споры не
									будут разрешены путем переговоров, споры подлежат разрешению в
									порядке, установленном действующим законодательством
									Российской Федерации.
								</li>
								<!--9.3-->
								<li>
									Настоящие Правила вступают в силу для Пользователя с момента
									его присоединения к ним и действуют в течение неопределенного
									срока.
								</li>
								<!--9.4-->
								<li>
									Настоящие Правила составлены на русском языке и могут быть
									предоставлены Пользователю для ознакомления на другом языке. В
									случае расхождения русскоязычной версии Правил и версии Правил
									на ином языке, применяются положения русскоязычной версии
									настоящих Правил.
								</li>
								<!--9.5-->
								<li>
									Если по тем или иным причинам одно или несколько положений
									настоящих Правил будут признаны недействительными или не
									имеющими юридической силы, это не оказывает влияния на
									действительность или применимость остальных положений.
								</li>
								<!--9.6-->
							</ol>
						</li>
						<!--9-->
					</ol>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'Terms',
  methods: {
    goBack() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        window.close()
      }
    },
  }
}
</script>
